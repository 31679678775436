import React, { useEffect, useState } from 'react';
import { useLocation,useHistory } from 'react-router-dom';
import { QuizContainer, QuizName, StyledBox, TimerCard, TimeLimit, QuestionsCard, OptionsList } from './styles';
import CommonButton from '../../components/common/CommonButton/CommonButton';
import { ButtonContainer } from '../../components/CreateEvent/style';
import { Main } from '../../components/AdminTeamCreation/styles';
import { NextArrows, SubmitTickIcon } from '../../utils/icons';
// import PointsPopup from './PointsPopup';
import { fetchApi } from '../../utils/methods';
import { toast } from 'react-toastify';
import { GetWellnessQuizesAnswers, SaveUserQuizResponse  } from '../../redux/constants/apiConstants';
import LazyImage from '../../components/common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

function QuizQuestionsAnswers() {
  const location = useLocation();
  const history = useHistory();
  const { state } = location;
  const [quizData, setQuizData] = useState([]);
  const [quizTitle] = useState(state?.quizTitle || '');
  const [quizTime] = useState(state?.quizTime || '0m');
  const [quiz_Id] = useState(state?.quizId || '');
  const [quiz_Type] = useState(state?.quizType);
  // const [quiz_Point] = useState(state?.quizPoints);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [detailsPopup, showDetailPopup] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [totalTimeTaken, setTotalTimeTaken] = useState(0);
  const [timeTakenPerQuestion, setTimeTakenPerQuestion] = useState({});
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [countdownRef, setCountdownRef] = useState(null);
  const [quizResponse,setQuizresponse] = useState({});

  //   window.console.log("quizTime",quizTime)

  useEffect(() => {
    getWellnessQuizesAnswers();
    return () => {
      if (countdownRef) {
        window.clearInterval(countdownRef);
      }
    };
  }, []);

  useEffect(() => {
    if (timeLeft <= 0 && countdownRef) {
      handleTimeExpiry();
    }
  }, [timeLeft]);

 

  // window.console.log("quiz_Type",quiz_Type)

  const getWellnessQuizesAnswers = async () => {
    let apiUrl = `${GetWellnessQuizesAnswers}/${quiz_Id}`;
    try {
      const res = await fetchApi(apiUrl, 'GET');
      if (res.status === 'success') {
        setQuizData(res.data);
        setQuestionStartTime(Date.now());

        const initialTime = (quizTime);
        setTimeLeft(initialTime);

        if (quiz_Type === 1) {
          startCountdown(initialTime);
        }
        else if(quiz_Type === 0){
          startCountdown(initialTime);
        }
      } else if (res.status === 'failed'){

        toast.error(res.data.message);
        history.push({
          pathname: "/education/adventure",
        });
      }
      else{
        toast.error(res.message);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const startCountdown = (initialTime) => {
    if (countdownRef) {
      window.clearInterval(countdownRef);
    }

    setTimeLeft(initialTime); 
    const timer = window.setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          window.clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    setCountdownRef(timer);
  };

  // const handleTimeExpiry = () => {
  //   const currentQuizId = quizData[currentIndex]?.id;

  //   if (quiz_Type === 0) {
  //     const timeSpent = (Date.now() - questionStartTime) / 1000;
  //     setTotalTimeTaken(totalTimeTaken + timeSpent);
  //   } else {
  //     setTimeTakenPerQuestion({
  //       ...timeTakenPerQuestion,
  //       [currentQuizId]: quizTime, 
  //     });
  //     setTotalTimeTaken(totalTimeTaken +quizTime);
  //   }

  //   if (quiz_Type === 1 && currentIndex < quizData.length - 1) {
  //     handleNextQuestion();
  //   }
  //   else {
  //     submitQuizAnswers(); 
  //     // submitQuizAnswers();
  //     // showDetailPopup(true);
  //   }
  // };


  const handleTimeExpiry = () => {
    const currentQuizId = quizData[currentIndex]?.id;
  
    if (quiz_Type === 0) {
      const timeSpent = (Date.now() - questionStartTime) / 1000;
      setTotalTimeTaken(totalTimeTaken + timeSpent);
      submitQuizAnswers(currentQuizId, timeSpent);
    } else if (quiz_Type === 1) {
      const timeSpent = (Date.now() - questionStartTime) / 1000;
  
      if (currentIndex === quizData.length - 1) {
        setTimeTakenPerQuestion({
          ...timeTakenPerQuestion,
          [currentQuizId]: timeSpent,
        });
        setTotalTimeTaken(totalTimeTaken + timeSpent);
        
        submitQuizAnswers(currentQuizId, timeSpent);
      } else {
        setTimeTakenPerQuestion({
          ...timeTakenPerQuestion,
          [currentQuizId]: quizTime, 
        });
        setTotalTimeTaken(totalTimeTaken + quizTime);
  
        handleNextQuestion();
      }
    }
  };
  


  

  
  const handleAnswerSelection = (quizId, answerId) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [quizId]: answerId,
    });
  };

  const handleNextQuestion = () => {
    const currentQuizId = quizData[currentIndex]?.id;
    const timeTaken = (Date.now() - questionStartTime) / 1000;

    // window.console.log("handle question currentQuizId",currentQuizId)
    
    // window.console.log("handle question timeTaken",timeTaken)

    setTimeTakenPerQuestion((prev) => ({
      ...prev,
      [currentQuizId]: timeTaken,
    }));
  
  
    // window.console.log("current index",currentIndex,quizData.length - 1)
    if (currentIndex < quizData.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setQuestionStartTime(Date.now());
  
      if (quiz_Type === 1) {
        const initialTime = quizTime;
        setTimeLeft(initialTime);
        startCountdown(initialTime);
      }
    } else {
    
      submitQuizAnswers(currentQuizId,timeTaken); 
   
      // submitQuizAnswers();
      // showDetailPopup(true);
    }
  };
  

  const submitQuizAnswers = async (lastQuizId = null , timeTaken = null) => {
    // window.console.log("currentQuizId----timeTaken",lastQuizId)
    // window.console.log("timeTaken",timeTaken)

    // window.console.log("hiii")
    let userQuestionAnswers;
    let totalTimeForAllQuestions = 0;
    // window.console.log('Quiz Type:', quiz_Type);
    // window.console.log('Selected Answers:', selectedAnswers);

   

  
    if (quiz_Type === 0) {
      const remainingTime = timeLeft;  
      // window.console.log("remainingTime",remainingTime)
  
      userQuestionAnswers = quizData.map((quiz,index) => ({
        question_id: quiz.id,
        answer_id: selectedAnswers[quiz.id] !== undefined ? selectedAnswers[quiz.id] : null, 
        time_taken: index === quizData.length - 1 ? quizTime - remainingTime : 0, 
      }));
      
    } else if (quiz_Type === 1) {
      userQuestionAnswers = quizData.map((quiz) => ({
        question_id: quiz.id,
        answer_id: selectedAnswers[quiz.id] !== undefined ? selectedAnswers[quiz.id] : null, 
        time_taken: quiz.id === lastQuizId ? timeTaken : timeTakenPerQuestion[quiz.id] > parseInt(quizTime) ? parseInt(quizTime) : timeTakenPerQuestion[quiz.id] , 
      }));
      // window.console.log("userQuestionAnswers",userQuestionAnswers)
      // totalTimeForAllQuestions = Object.values(userQuestionAnswers).reduce((acc, time) => acc + time, 0);
     

      totalTimeForAllQuestions = userQuestionAnswers.reduce((acc, answer) => {
        const time = answer.time_taken; 
      
        // window.console.log("Accumulator (acc):", acc);
        // window.console.log("Time for current question:", time);
      
        return acc + time;  
      }, 0);  
      
      setTotalTimeTaken(totalTimeForAllQuestions); 

    } else {
      window.console.error('Unexpected quiz type:', quiz_Type);
      return;
    }
    // window.console.log("totaleTimeTaken",totalTimeTaken)
  
    const payload = {
      launch_quiz_id: quiz_Id,
      // total_time_taken: quiz_Type === 0 ? totalTimeTaken  : totalTimeForAllQuestions,  
      total_time_taken: quiz_Type === 0 ? quizTime - timeLeft  : totalTimeForAllQuestions,  
      user_question_answers: userQuestionAnswers,
    };
  
    // window.console.log('Payload:', payload);
    // setQuizresponse(payload)
  
    try {
      const response = await fetchApi(SaveUserQuizResponse, 'POST', payload);
      if (response.status === 'success') {
        // window.console.log("response",response)
        setQuizresponse(response.data)
        toast.success('Quiz submitted successfully!');
        history.push({
          pathname: `/wellness-resource/quiz-details/${quiz_Id}`,
          state: {
            quizResponse: response.data,
            showPopup: true,
            totalQuestions:quizData.length 
          }
        });
     
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  };
  

  window.console.log("response",quizResponse)

  const renderCurrentQuestion = () => {
    const currentQuiz = quizData[currentIndex];
    if (!currentQuiz) return null;

    return (
      <>
        <QuestionsCard>
          <div className="questionNumber">
            {currentIndex + 1}/{quizData.length} Questions
          </div>
          <div className="questions">{currentQuiz.quiz}</div>
        </QuestionsCard>
        {currentQuiz.quiz_answers.map((answer) => (
          <OptionsList
            key={answer.id}
            onClick={() => handleAnswerSelection(currentQuiz.id, answer.id)}
            className={selectedAnswers[currentQuiz.id] === answer.id ? 'selected' : ''}
          >
            <div className="options">{answer.choice}</div>
          </OptionsList>
        ))}
      </>
    );
  };

  // const formatTime = (seconds) => {
  //   window.console.log("formate time",seconds)
  //   if (isNaN(seconds)) return '00:00';
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  // };

  const formatTime = (seconds) => {
    if (isNaN(seconds)) return '00m 00s';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${String(remainingSeconds).padStart(2, '0')}s`;
  };

  return (
    <>
      <QuizContainer>
        <QuizName>{quizTitle} Quiz</QuizName>
        <StyledBox>
          <TimerCard>
            <TimeLimit>
              <LazyImage div={"box"} src={ImageUrl + "/event-section/timer.svg"}/> &nbsp;
              {quiz_Type === 0 ? "Complete quiz in" : "Time for answer"} : {formatTime(timeLeft)}
            </TimeLimit>
          </TimerCard>
          {renderCurrentQuestion()}
        </StyledBox>
      </QuizContainer>
      <ButtonContainer>
        <Main>
          <CommonButton
            btnType={'squareIcon'}
            onClick={handleNextQuestion}
            title={currentIndex < quizData.length - 1 ? 'Next' : 'Submit'}
            styles={{ marginAuto: 'auto', color: '#007AB1' }}
            nextIcon={currentIndex < quizData.length - 1 ? NextArrows("white") :  SubmitTickIcon()}
          />
        </Main>
      </ButtonContainer>
      {/* {detailsPopup && <PointsPopup showModal={detailsPopup} onClose={() => showDetailPopup(false)}  quizResponse={quizResponse} />} */}
    </>
  );
}

export default QuizQuestionsAnswers;